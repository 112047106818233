export default class DealerSearchTeaser extends HTMLElement {
	/**
	* @constructs DealerSearchTeaser
	* @this DealerSearchTeaser
	* @hideconstructor
	* @class DealerSearchTeaser
	* @extends HTMLElement
	* @classdesc Custom Element to redirect to the Dealer Search
	*/
	constructor() {
		super();

		this.selectorSearchInputField = '.nm-dealer-search-inputfield';
		this.selectorDealerSearchButton = '.nm-dealer-search-button';
		this.selectorDealerSearchMapImage = '.nm-dealer-search-image-container';
		this.selectorDealerGPSButton = '.nm-dealer-search-gps-icon';
	}

	/**
	* @function connectedCallback
	* @memberof DealerSearchTeaser
	* @ignore
	* @returns {void}
	*/
	connectedCallback() {
		this._initializeModule();
	}

	/**
	* @function disconnectedCallback
	* @memberof DealerSearchTeaser
	* @ignore
	* @returns {void}
	*/
	disconnectedCallback() {
		this._removeInteractionEvents();
	}

	/**
	 * responsible for the execution of all methods needed to initialize
	 * the component
	 * @private
	 * @function _initializeModule
	 * @memberof DealerSearchTeaser
	 * @returns {void}
	 */
	_initializeModule() {
		this._initializeVariables();
		this._addInteractionEvents();
	}

	/**
	* instantiates the dom elements to be interactively updated
	* @private
	* @function _initializeVariables
	* @memberof DealerSearchTeaser
	* @returns {void}
	*/
	_initializeVariables() {
		this.dealerSearchButton = this.querySelector(this.selectorDealerSearchButton);
		this.dealerSearchMapImage = this.querySelector(this.selectorDealerSearchMapImage);
		this.dealerGPSButton = this.querySelector(this.selectorDealerGPSButton);
		this.searchInputField = this.querySelector(this.selectorSearchInputField);
	}

	/**
	 * binds the needed events
	 * @private
	 * @function _addInteractionEvents
	 * @memberof DealerSearchTeaser
	 * @returns {void}
	 */
	_addInteractionEvents() {
		if (this.dealerSearchButton) {
			this.dealerSearchButton.addEventListener('click', this._handleSearchByButtonOrImageClick.bind(this));
		}

		if (this.dealerSearchMapImage) {
			this.dealerSearchMapImage.addEventListener('click', this._handleSearchByButtonOrImageClick.bind(this));
		}

		if (this.dealerGPSButton) {
			this.dealerGPSButton.addEventListener('click', this._handleSearchByGeolocationClick.bind(this));
		}

		if (this.searchInputField) {
			this.searchInputField.addEventListener('keyup', this._handleSearchByEnter.bind(this));
		}
	}

	/**
	 * removes all binded events
	 * @private
	 * @function _removeInteractionEvents
	 * @memberof DealerSearchTeaser
	 * @returns {void}
	 */
	_removeInteractionEvents() {
		if (this.dealerSearchButton) {
			this.dealerSearchButton.removeEventListener('click', this._handleSearchByButtonOrImageClick);
		}

		if (this.dealerSearchMapImage) {
			this.dealerSearchMapImage.removeEventListener('click', this._handleSearchByButtonOrImageClick);
		}

		if (this.dealerGPSButton) {
			this.dealerGPSButton.removeEventListener('click', this._handleSearchByGeolocationClick);
		}

		if (this.searchInputField) {
			this.searchInputField.removeEventListener('keyup', this._handleSearchByEnter);
		}
	}

	/**
	 * call openDshModule and deliver suffix (URL Parameter) if available
	 * @private
	 * @function _handleSearchByButtonOrImageClick
	 * @memberof DealerSearchTeaser
	 * @param {event} event_ - Click Event
	 * @returns {void}
	 */
	_handleSearchByButtonOrImageClick(event_) {
		let urlParameter = '';
		let searchValue;

		event_.preventDefault();

		if (this.searchInputField) {
			searchValue = this.searchInputField.value;

			if (!!searchValue) {
				urlParameter = '#search=' + searchValue;
			}
		}
		this._openDshModule(urlParameter);
	}

	/**
	 * Opens a new window and loads the url corresponding to the dsh module.
	 * @private
	 * @function _openDshModule
	 * @memberof DealerSearchTeaser
	 * @param {String} urlParameter_ - the suffix to add to the url (can be an empty String)
	 * @returns {void}
	 */
	_openDshModule(urlParameter_) {
		const target = this.getAttribute('data-dsh-url-target') || '_blank';
		let url = '';

		if (!!this.getAttribute('data-dsh-url')) {
			url = this.getAttribute('data-dsh-url');

			if (!!urlParameter_) {
				let searchParam = (url.indexOf('#') !== -1) ? '&'+urlParameter_.substring(1) : urlParameter_;
				url += searchParam;
			}
			window.open(url, target);
		}
	}

	/**
	 * call handleSearchHandler on Keyboard Enter action
	 * @private
	 * @function _handleSearchByEnter
	 * @memberof DealerSearchTeaser
	 * @param {event} event_ - Keyboard Event
	 * @returns {void}
	 */
	_handleSearchByEnter(event_) {
		const enterKeyCode = 13;

		if (!event_) {
			return;
		}

		if (event_.keyCode === enterKeyCode) {
			this._handleSearchByButtonOrImageClick(event_);
		}
	}

	/**
	 * call openDshModule with geolocation suffix (URL parameter)
	 * @private
	 * @function _handleSearchByGeolocationClick
	 * @memberof DealerSearchTeaser
	 * @param {event} event_ - Click Event
	 * @returns {void}
	 */
	_handleSearchByGeolocationClick(event_) {
		const urlGeoParameter = '#geolocation=true';

		event_.preventDefault();
		this._openDshModule(urlGeoParameter);
	}
}

if (window.customElements.get('audi-dealer-search-teaser') === undefined) {
	window.customElements.define('audi-dealer-search-teaser', DealerSearchTeaser);
}
